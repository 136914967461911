import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { SetAccessToken } from './actions/app-state.actions';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, HttpClientModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    title = 'audifly-frontend';

    constructor(private readonly store: Store) {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
            this.store.dispatch(new SetAccessToken(accessToken));
        }
    }
}
