import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppState } from '../../states/app.state';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
    LoadAvailableBranches,
    LoadExampleDirectory,
    SetProcessingActivityDirectory,
} from '../../actions/app-state.actions';
import { RouterLink } from '@angular/router';
import { TemplateActivity } from '../../types/template-activity.type';
import { ProcessingActivityListItemComponent } from '../../components/processing-activity-list-item/processing-activity-list-item.component';

@Component({
    selector: 'app-directory-wizard',
    standalone: true,
    imports: [CommonModule, RouterLink, ProcessingActivityListItemComponent],
    templateUrl: './directory-wizard.component.html',
    styleUrl: './directory-wizard.component.scss',
})
export class DirectoryWizardComponent {
    @Select(AppState.availableBranches) availableBranches$?: Observable<
        string[]
    >;

    exampleDirectory$ = this.store.select(AppState.exampleDirectory).pipe(
        tap(directory => {
            if (directory) {
                this.directory = directory;
            }
        })
    );

    directory: TemplateActivity[] = [];

    selectedBranch?: string;

    step$: BehaviorSubject<'branch' | 'activities' | 'finish'> =
        new BehaviorSubject<'branch' | 'activities' | 'finish'>('branch');

    constructor(private readonly store: Store) {
        this.store.dispatch(new LoadAvailableBranches());
    }

    setSelectedBranch(branch: string) {
        this.selectedBranch = branch;
    }

    async setStep(step: 'branch' | 'activities' | 'finish') {
        if (this.step$.getValue() === 'branch') {
            if (this.selectedBranch)
                this.store.dispatch(
                    new LoadExampleDirectory(this.selectedBranch)
                );
        }

        if (this.step$.getValue() === 'activities') {
            if (this.exampleDirectory$) {
                if (this.selectedBranch)
                    this.store.dispatch(
                        new SetProcessingActivityDirectory(
                            this.directory.filter(
                                activity => activity.isSelected
                            )
                        )
                    );
            }
        }

        this.step$.next(step);
    }
}
