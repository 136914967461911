import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ProcessingActivityStateType } from '../types/processing-acitity-state.type';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { map } from 'rxjs';
import {
    DeleteProcessingActivity,
    LoadProcessingActivities,
    UpdateProcessingActivity,
} from '../actions/processing-activity-state.actions';

@State<ProcessingActivityStateType>({
    name: 'ProcessingActivityState',
})
@Injectable()
export class ProcessingActivityState {
    @Selector([ProcessingActivityState])
    static processingActivities(state: ProcessingActivityStateType) {
        return state.processingActivities;
    }

    @Selector([ProcessingActivityState])
    static synced(state: ProcessingActivityStateType) {
        return state.synced;
    }

    constructor(
        private readonly apiService: ApiService,
        private readonly store: Store
    ) {}

    @Action(LoadProcessingActivities)
    loadProcessingActivities(ctx: StateContext<ProcessingActivityStateType>) {
        return this.apiService.getProcessingActivities().pipe(
            map(activities => {
                ctx.patchState({
                    processingActivities: activities,
                });
            })
        );
    }

    @Action(UpdateProcessingActivity)
    updateProcessingActivity(
        ctx: StateContext<ProcessingActivityStateType>,
        action: UpdateProcessingActivity
    ) {
        if (!action.activity.id) {
            throw new Error('activity needs to have id');
        }

        const activities = ctx
            .getState()
            .processingActivities.filter(activity => {
                return activity.id !== action.activity.id;
            });

        activities.push(action.activity);

        ctx.patchState({
            processingActivities: activities,
        });

        return this.apiService.updateProcessingActivity(
            action.activity.id,
            action.activity
        );
    }

    @Action(DeleteProcessingActivity)
    deleteProcessingActivity(
        ctx: StateContext<ProcessingActivityStateType>,
        action: DeleteProcessingActivity
    ) {
        const activities = ctx.getState().processingActivities;

        console.log('deleting ' + action.activityId);

        ctx.patchState({
            processingActivities: activities.filter(
                a => a.id !== action.activityId
            ),
            synced: false,
        });

        this.apiService
            .deleteProcessingActivity(action.activityId)
            .forEach(result => {
                console.log(result);

                ctx.patchState({
                    synced: true,
                });
            });
    }
}
