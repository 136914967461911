import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoginUser } from '../../actions/app-state.actions';
import { Observable } from 'rxjs';
import { AppState } from '../../states/app.state';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './login.component.html',
})
export class LoginComponent {
    @Select(AppState.loggedIn) loggedIn$?: Observable<boolean>;

    loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
    });

    constructor(
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly store: Store
    ) {
        this.loggedIn$?.forEach(loggedIn => {
            if (loggedIn) {
                this.router.navigate(['/']);
            }
        });
    }

    async submitLogin() {
        const username = this.loginForm.getRawValue().username;
        const password = this.loginForm.getRawValue().password;

        if (username && password) {
            this.store.dispatch(new LoginUser(username, password));
        }
    }
}
