import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../states/app.state';

export const accessTokenInterceptor: HttpInterceptorFn = (req, next) => {
    const store = inject(Store);
    const accessToken = store.selectSnapshot(AppState.token);

    if (accessToken) {
        req = req.clone({
            headers: req.headers.set('authorization', accessToken),
            body: req.body,
        });
    }

    return next(req);
};
