import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProcessingActivity } from '../../types/processing-activity.type';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProcessingActivityListItemComponent } from '../../components/processing-activity-list-item/processing-activity-list-item.component';
import { LoadProcessingActivities } from '../../actions/processing-activity-state.actions';
import { ProcessingActivityState } from '../../states/processing-activity.state';
import { Logout } from '../../actions/app-state.actions';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [CommonModule, ProcessingActivityListItemComponent, RouterLink],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {
    @Select(ProcessingActivityState.processingActivities)
    processingActivityDirectory$?: Observable<ProcessingActivity[]>;

    constructor(
        private readonly store: Store,
        private readonly router: Router
    ) {
        this.store.dispatch(new LoadProcessingActivities());
    }

    logout() {
        this.store.dispatch(new Logout());
        this.router.navigateByUrl('login');
    }
}
