import { ProcessingActivity } from '../types/processing-activity.type';

export class LoadProcessingActivities {
    static readonly type = '[ProcessingActivities] Load Processing Activities';
}

export class SyncProcessingActivities {
    static readonly type = '[ProcessingActivities] Sync Processing Activities';
}

export class SyncProcessingActivity {
    static readonly type = '[ProcessingActivities] Sync Processing Activity';
    constructor(public readonly activityId: string) {}
}

export class UpdateProcessingActivity {
    static readonly type = '[ProcessingActivities] Update Processing Activity';
    constructor(public readonly activity: Partial<ProcessingActivity>) {}
}

export class DeleteProcessingActivity {
    static readonly type = '[ProcessingActivities] Delete Processing Activity';
    constructor(public readonly activityId: string) {}
}
