import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppStateType } from '../types/app-state.type';
import { Injectable } from '@angular/core';
import {
    LoadAvailableBranches,
    LoadExampleDirectory,
    LoginUser,
    Logout,
    SetAccessToken,
    SetProcessingActivityDirectory,
} from '../actions/app-state.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs';
import { TemplateActivity } from '../types/template-activity.type';
import { ApiService } from '../services/api.service';

@State<AppStateType>({
    name: 'AppState',
})
@Injectable()
export class AppState {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiService: ApiService
    ) {}

    @Selector([AppState])
    static loggedIn(state: AppStateType) {
        return state.loggedIn;
    }

    @Selector([AppState])
    static availableBranches(state: AppStateType) {
        return state.availableBranches;
    }

    @Selector([AppState])
    static exampleDirectory(state: AppStateType) {
        return state.exampleDirectory;
    }

    @Selector([AppState])
    static processingActivityDirectory(state: AppStateType) {
        return state.processingActivityDirectory;
    }

    @Selector([AppState])
    static token(state: AppStateType) {
        return state.token;
    }

    @Action(LoginUser)
    loginUser(ctx: StateContext<AppStateType>, action: LoginUser) {
        return this.httpClient
            .post<string>(environment.apiBaseUrl + '/user/login', {
                email: action.email,
                password: action.password,
            })
            .pipe(
                tap(result => {
                    if (result) {
                        ctx.patchState({
                            token: result,
                            loggedIn: true,
                        });

                        // renew access token every 15 minutes
                        setInterval(
                            () => {
                                if (ctx.getState().token === undefined) {
                                    return;
                                }

                                this.apiService
                                    .renewAccessToken()
                                    .forEach(token => {
                                        ctx.patchState({ token: token });
                                        localStorage.setItem(
                                            'accessToken',
                                            token
                                        );
                                    });
                            },
                            15 * 60 * 1000
                        );

                        localStorage.setItem('accessToken', result);
                    }
                })
            );
    }

    @Action(LoadAvailableBranches)
    loadAvailableBranches(ctx: StateContext<AppStateType>) {
        return this.httpClient.get(environment.apiBaseUrl + '/branches').pipe(
            tap(branches => {
                if (Array.isArray(branches)) {
                    ctx.patchState({
                        availableBranches: branches
                            .sort()
                            .filter(branch => branch),
                    });
                }
            })
        );
    }

    @Action(LoadExampleDirectory)
    loadExampleDirectory(
        ctx: StateContext<AppStateType>,
        action: LoadExampleDirectory
    ) {
        return this.httpClient
            .get<
                TemplateActivity[]
            >(environment.apiBaseUrl + '/processing-activities/template/' + action.branch)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        exampleDirectory: result,
                    });
                })
            );
    }

    @Action(SetProcessingActivityDirectory)
    setProcessingActivityDirectory(
        ctx: StateContext<AppStateType>,
        action: SetProcessingActivityDirectory
    ) {
        const activities = action.directory;

        return this.apiService.setProcessingActivities(activities);
    }

    @Action(SetAccessToken)
    setAccessToken(ctx: StateContext<AppStateType>, action: SetAccessToken) {
        ctx.patchState({
            token: action.token,
        });

        // renew access token every 15 minutes
        setInterval(
            () => {
                if (ctx.getState().token === undefined) {
                    return;
                }

                this.apiService.renewAccessToken().forEach(token => {
                    ctx.patchState({ token: token });
                    localStorage.setItem('accessToken', token);
                });
            },
            15 * 60 * 1000
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AppStateType>) {
        ctx.patchState({
            token: undefined,
        });

        localStorage.removeItem('accessToken');
    }
}
