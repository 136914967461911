import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ProcessingActivityState } from '../../states/processing-activity.state';
import { UpdateProcessingActivity } from '../../actions/processing-activity-state.actions';

@Component({
    selector: 'app-edit',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, RouterLink],
    templateUrl: './edit.component.html',
    styleUrl: './edit.component.scss',
})
export class EditComponent {
    activityId = this.activatedRoute.snapshot.paramMap.get('activityId');
    companyId?: string;

    activity$ = this.store
        .select(ProcessingActivityState.processingActivities)
        .pipe(
            map(activities => {
                return activities.filter(activity => {
                    return activity.id === this.activityId;
                });
            })
        );

    /*
    activity$ = this.currentUser$.pipe(
        map((currentUser) => {
            if(currentUser) {
                this.companyId = currentUser.companyId;

                if(this.activityId) {
                    return this.apiService.getProcessingActivity(
                        currentUser.companyId, 
                        this.activityId
                    );
                }
            }

            return of(undefined);
        }),
        mergeMap((innerObservable) => innerObservable)
    );
    */

    processingActivityForm = new FormGroup({
        name: new FormControl<string | undefined>('', Validators.required),
        purposeDescription: new FormControl<string | undefined>(''),
    });

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly store: Store
    ) {
        this.activity$.forEach(activity => {
            if (!activity) {
                return;
            }

            console.log(activity);

            this.processingActivityForm.setValue({
                name: activity[0].name,
                purposeDescription: activity[0].purpose_description,
            });
        });
    }

    updateActivity() {
        const formValue = this.processingActivityForm.getRawValue();

        if (
            formValue.name === null ||
            this.activityId === null ||
            formValue.purposeDescription === null
        ) {
            return;
        }

        this.store.dispatch(
            new UpdateProcessingActivity({
                id: this.activityId,
                name: formValue.name,
                purpose_description: formValue.purposeDescription,
            })
        );
    }
}
