import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
} from '@angular/core';
import { Router, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
    provideHttpClient,
    withFetch,
    withInterceptors,
} from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './states/app.state';
import { ProcessingActivityState } from './states/processing-activity.state';
import { accessTokenInterceptor } from './interceptors/access-token.interceptor';
import * as Sentry from '@sentry/angular-ivy';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(
            withFetch(),
            withInterceptors([accessTokenInterceptor])
        ),
        importProvidersFrom(
            NgxsModule.forRoot([AppState, ProcessingActivityState])
        ),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
};
