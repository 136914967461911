import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppState } from '../states/app.state';

export const authGuard: CanActivateFn = () => {
    const store: Store = inject(Store);
    const router: Router = inject(Router);
    const accessToken = localStorage.getItem('accessToken')
        ? localStorage.getItem('accessToken')
        : store.selectSnapshot(AppState.token);

    if (!accessToken) {
        return router.createUrlTree(['login']);
    }

    return true;
};
