<div class="flex flex-col gap-10 p-20">
    <h1 class="text-2xl font-bold">Verzeichnis der Verarbeitungstätigkeiten</h1>
    <button (click)="logout()">logout</button>

    <div class="flex flex-col">
        @if (processingActivityDirectory$ | async; as processingDirectory) {
            @for (activity of processingDirectory; track $index) {
                <app-processing-activity-list-item
                    class="w-full"
                    [processingActivity]="
                        activity
                    "></app-processing-activity-list-item>
            } @empty {
                <a routerLink="directory-wizard">Wizard starten</a>
            }
        }
    </div>
</div>
