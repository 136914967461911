import { ProcessingActivity } from '../types/processing-activity.type';

export class LoginUser {
    static readonly type = '[AppState] Login User';
    constructor(
        public readonly email: string,
        public readonly password: string
    ) {}
}

export class SetUserEmail {
    static readonly type = '[AppState] Set User Name';
    constructor(public readonly email: string) {}
}

export class LoadAvailableBranches {
    static readonly type = '[AppState] Load Available Branches';
}

export class LoadExampleDirectory {
    static readonly type = '[AppState] Load Example Directory';
    constructor(public readonly branch: string) {}
}

export class SetProcessingActivityDirectory {
    static readonly type = '[AppState] Set Processing Activity Directory';
    constructor(public readonly directory: ProcessingActivity[]) {}
}

export class SetAccessToken {
    static readonly type = '[AppState] Set Access Token';
    constructor(public readonly token: string) {}
}

export class Logout {
    static readonly type = '[AppState] Logout';
}
