<div class="flex flex-col gap-10 p-20">
    <h1>Verarbeitungstätigkeit</h1>

    <a [routerLink]="'/'" class="flex w-fit">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#092B40"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M12 8L8 12L12 16"
                stroke="#092B40"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M16 12H8"
                stroke="#092B40"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </a>

    @for (activity of activity$ | async; track $index) {
        <form
            [formGroup]="processingActivityForm"
            class="flex flex-col w-full gap-5">
            <div class="flex flex-col gap-2">
                <label for="processingActivityName" class="text-lg font-bold">
                    Bezeichnung der Verarbeitungstätigkeit
                </label>
                <input
                    #processingActivityName
                    formControlName="name"
                    class="w-full rounded-sm p-2" />
            </div>
            <div class="flex flex-col gap-2">
                <label for="processingActivityName" class="text-lg font-bold">
                    Bezeichnung der Verarbeitungstätigkeit
                </label>
                <textarea
                    #purposeDescription
                    formControlName="purposeDescription"
                    class="w-full rounded-sm p-2">
                </textarea>
            </div>
        </form>

        <button
            [disabled]="!processingActivityForm.dirty"
            (click)="updateActivity()"
            class="disabled:bg-gray-500 text-white w-fit bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            absenden
        </button>
    } @empty {
        <p>Es konnten keine Daten gefunden werden</p>
    }
</div>
