<div class="flex w-screen h-screen items-center justify-center">
    <div class="flex flex-col gap-8 w-96 justify-center items-center">
        <h1 class="text-audifly-sea-blue text-6xl font-telma">audifly</h1>

        <form
            class="flex flex-col gap-4 justify-center items-center"
            [formGroup]="loginForm">
            <input
                class="border px-5 py-3 border-black rounded-2xl w-full bg-audifly-background-light"
                type="text"
                id="username"
                name="username"
                placeholder="username"
                formControlName="username" />
            <input
                class="border px-5 py-3 border-black rounded-2xl w-full bg-audifly-background-light"
                type="password"
                id="password"
                name="password"
                placeholder="password"
                formControlName="password" />
            <p class="font-light">neu hier? Jetzt registrieren</p>
            <button
                [disabled]="!loginForm.valid"
                class="rounded-md border-black border py-3 px-6 w-fit"
                (click)="submitLogin()">
                login
            </button>
        </form>
    </div>
</div>
