import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcessingActivity } from '../types/processing-activity.type';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    apiBaseUrl = '/';

    constructor(private readonly httpClient: HttpClient) {}

    getAvailableBranches() {
        return this.httpClient.get<string[]>(this.apiBaseUrl + 'branches');
    }

    getProcessingActivities() {
        return this.httpClient.get<ProcessingActivity[]>(
            this.apiBaseUrl + `activities`
        );
    }

    getProcessingActivity(activityId: string) {
        return this.httpClient.get<ProcessingActivity[]>(
            this.apiBaseUrl + `activities/${activityId}`
        );
    }

    updateProcessingActivity(
        activityId: string,
        data: Partial<ProcessingActivity>
    ) {
        return this.httpClient.put<string[]>(
            this.apiBaseUrl + `activities/${activityId}`,
            {
                activity: data,
            }
        );
    }

    setProcessingActivities(activities: ProcessingActivity[]) {
        return this.httpClient.post(this.apiBaseUrl + `activities`, {
            activities: activities,
        });
    }

    renewAccessToken() {
        return this.httpClient.post<string>(this.apiBaseUrl + 'user/renew', {});
    }

    deleteProcessingActivity(activityId: string) {
        return this.httpClient.delete(
            this.apiBaseUrl + `activities/${activityId}`,
            {}
        );
    }
}
