@if (isTemplate && activityTemplate) {
    <div class="flex flex-col gap-2 w-full">
        <div class="flex flex-row p-2 border-b w-full gap-5">
            <input
                type="checkbox"
                [checked]="activityTemplate.isSelected"
                (change)="
                    activityTemplate.isSelected = !activityTemplate.isSelected
                " />
            <p class="w-full font-bold">{{ activityTemplate.name }}</p>
        </div>
    </div>
}
@if (!isTemplate && processingActivity) {
    <div class="flex flex-col gap-2 w-full">
        <div class="flex flex-row p-2 border-b w-full gap-5">
            <p class="w-full font-bold">{{ processingActivity.name }}</p>
            <button>
                <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 12.5C1 12.5 5 4.5 12 4.5C19 4.5 23 12.5 23 12.5C23 12.5 19 20.5 12 20.5C5 20.5 1 12.5 1 12.5Z"
                        stroke="#092B40"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                        stroke="#092B40"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            <button [routerLink]="'/edit/' + processingActivity.id">
                <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17 3.50006C17.2626 3.23741 17.5744 3.02907 17.9176 2.88693C18.2608 2.74479 18.6286 2.67163 19 2.67163C19.3714 2.67163 19.7392 2.74479 20.0824 2.88693C20.4256 3.02907 20.7374 3.23741 21 3.50006C21.2626 3.7627 21.471 4.07451 21.6131 4.41767C21.7553 4.76083 21.8284 5.12862 21.8284 5.50006C21.8284 5.87149 21.7553 6.23929 21.6131 6.58245C21.471 6.92561 21.2626 7.23741 21 7.50006L7.5 21.0001L2 22.5001L3.5 17.0001L17 3.50006Z"
                        stroke="#092B40"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
            @if (processingActivity.id) {
                <button
                    (click)="deleteProcessingActivity(processingActivity.id)">
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3 6.5H5H21"
                            stroke="#092B40"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M19 6.5V20.5C19 21.0304 18.7893 21.5391 18.4142 21.9142C18.0391 22.2893 17.5304 22.5 17 22.5H7C6.46957 22.5 5.96086 22.2893 5.58579 21.9142C5.21071 21.5391 5 21.0304 5 20.5V6.5M8 6.5V4.5C8 3.96957 8.21071 3.46086 8.58579 3.08579C8.96086 2.71071 9.46957 2.5 10 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V6.5"
                            stroke="#092B40"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M10 11.5V17.5"
                            stroke="#092B40"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M14 11.5V17.5"
                            stroke="#092B40"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            }
            <button>
                <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20 9.5H11C9.89543 9.5 9 10.3954 9 11.5V20.5C9 21.6046 9.89543 22.5 11 22.5H20C21.1046 22.5 22 21.6046 22 20.5V11.5C22 10.3954 21.1046 9.5 20 9.5Z"
                        stroke="#092B40"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M5 15.5H4C3.46957 15.5 2.96086 15.2893 2.58579 14.9142C2.21071 14.5391 2 14.0304 2 13.5V4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5H13C13.5304 2.5 14.0391 2.71071 14.4142 3.08579C14.7893 3.46086 15 3.96957 15 4.5V5.5"
                        stroke="#092B40"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
}
