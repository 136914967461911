import { Component, Input } from '@angular/core';
import { TemplateActivity } from '../../types/template-activity.type';
import { ProcessingActivity } from '../../types/processing-activity.type';
import { RouterLink } from '@angular/router';
import { Store } from '@ngxs/store';
import { DeleteProcessingActivity } from '../../actions/processing-activity-state.actions';

@Component({
    selector: 'app-processing-activity-list-item',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './processing-activity-list-item.component.html',
    styleUrl: './processing-activity-list-item.component.scss',
})
export class ProcessingActivityListItemComponent {
    @Input() activityTemplate?: TemplateActivity;
    @Input() processingActivity?: ProcessingActivity;
    @Input() isTemplate: boolean = false;

    constructor(private readonly store: Store) {}

    deleteProcessingActivity(activityId: string) {
        if (
            confirm('Wollen Sie diese Verarbeitungstätigkeit sicher löschen?')
        ) {
            this.store.dispatch(new DeleteProcessingActivity(activityId));
        }
    }
}
