<div
    class="flex flex-col gap-4 w-full min-h-screen items-center justify-center p-20">
    @if ((step$ | async) === 'branch') {
        <div class="flex w-1/3 p-8 flex-col gap-12 text-center">
            <h1 class="font-bold text-2xl">Wählen Sie eine Branche</h1>

            <div class="flex flex-wrap justify-center items-center gap-2">
                @if (availableBranches$ | async; as availableBranches) {
                    @for (branch of availableBranches; track $index) {
                        <button
                            class="flex rounded-full border border-audifly-dark-blue px-4 py-2"
                            [class.bg-audifly-dark-blue]="
                                branch === selectedBranch
                            "
                            [class.text-audifly-background-light]="
                                branch === selectedBranch
                            "
                            (click)="setSelectedBranch(branch)">
                            {{ branch }}
                        </button>
                    }
                }
            </div>

            <button (click)="setStep('activities')">weiter</button>
        </div>
    }

    @if ((step$ | async) === 'activities') {
        @if (exampleDirectory$ | async; as exampleDirectory) {
            @for (activity of exampleDirectory; track $index) {
                <app-processing-activity-list-item
                    class="w-full"
                    [isTemplate]="true"
                    [activityTemplate]="
                        activity
                    "></app-processing-activity-list-item>
            }
        }

        <button (click)="setStep('finish')">weiter</button>
    }

    @if ((step$ | async) === 'finish') {
        <button routerLink="/">Fertigstellen</button>
    }
</div>
