import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DirectoryWizardComponent } from './pages/directory-wizard/directory-wizard.component';
import { HomeComponent } from './pages/home/home.component';
import { EditComponent } from './pages/edit/edit.component';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'directory-wizard',
        component: DirectoryWizardComponent,
        canActivate: [authGuard],
    },
    {
        path: 'edit/:activityId',
        component: EditComponent,
        canActivate: [authGuard],
    },
];
